import {Field} from 'Util/Query';

export class ga4Query {

    getGA4Data(options) {
        console.log(options);
        return new Field('ga4Data')
            .addFieldList([
                'ga4_data'
            ])
            .addArgument('source', 'String', options)
    }


}

export default new ga4Query();
