import {fetchQuery} from "@scandipwa/scandipwa/src/util/Request/Query";
import ga4Query from '../query/ga4.query';
import {isHomePageUrl} from 'Util/Url';
import browserHistory from 'Util/History';
import BrowserDatabase from 'Util/BrowserDatabase';
import {PAYMENT_TOTALS} from "Route/Checkout/Checkout.config";
import {getCartId, setCartId} from 'Util/Cart';
import {Field} from 'Util/Query';
import getStore from 'Util/Store';
import {CART_TOTALS} from "@scandipwa/scandipwa/src/store/Cart/Cart.reducer";
class GtmPlugin {

    allowedRequests = [
        'catalogsearch',
        'checkoutsuccess'
    ];

    requestGA4Data(source, id = 0, search = '') {
        let params = new URLSearchParams(window.location.search);
        const page = params.get('page') || 1;
        const sortKey = params.get('sortKey');
        const sortDirection = params.get('sortDirection');
        const customFilters = params.get('customFilters');

        console.log(params);
        console.log(page);
        console.log(sortKey);
        console.log(sortDirection);
        console.log(customFilters);




        this.getGA4Data(JSON.stringify({
            source: source,
            id: id,
            page:page,
            sort: {
                sortKey:sortKey,
                sortDirection:sortDirection,
            },
            customFilters:customFilters,
            search:search
        }));
    }
    async insertHeadScripts() {
        await this.getGTMHead('GTM');
        await this.getGTMHead('GA');
        await this.getGTMHead('FbPixel');
    }

    parseScripts(module, scripts, position = 'head', type = false) {
        if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
            window.dataLayer.length = 0;
            let gtmContainerReg = /GTM-/i;
            for (const gtmKey of Object.keys(window.google_tag_manager)) {
                if (gtmContainerReg.test(gtmKey) && window.google_tag_manager[gtmKey].dataLayer
                    && window.google_tag_manager[gtmKey].dataLayer.reset
                ) {
                    // console.log(gtmKey);
                    window.google_tag_manager[gtmKey].dataLayer.reset();
                }
            }
            gtmContainerReg = /G-/i;
            for (const gtmKey of Object.keys(window.google_tag_manager)) {
                if (gtmContainerReg.test(gtmKey) && window.google_tag_manager[gtmKey].dataLayer
                    && window.google_tag_manager[gtmKey].dataLayer.reset
                ) {
                    // console.log(gtmKey);
                    window.google_tag_manager[gtmKey].dataLayer.reset();
                }
            }
        }
        // console.log(module + ' - ' + position);
        // console.log(scripts);





        scripts = document.createRange().createContextualFragment(scripts).children;

        // console.log(scripts);
        Object.entries(scripts).map(([index, script]) => {
            script.id = module.toLowerCase() + '_' + index;
            // console.log(position);
            // console.log(script);
            // console.log(document.getElementById(script.id));
            // if (!document.getElementById(script.id)) {
                document.querySelectorAll('head [src*="googletagmanager"]').forEach( n => n.remove() );
                document.querySelectorAll('head [src*="fbevents"]').forEach( n => n.remove() );
                document.querySelectorAll('[id*="'+script.id+'"]').forEach( n => n.remove() );
                document[position].prepend(script);

            // }
        });
    }

    async insertGTMCodeScripts() {
        await this.getGTMCode('GTM', 'category', 8);
        // await this.getGTMCode('GA', 'category', 8);
        // await this.getGTMCode('FbPixel', 'category', 8);
    }

    getGA4Data(options) {
        fetchQuery(
            ga4Query.getGA4Data(options)
        ).then(
            (
                async (data) => {

                    console.clear();
                    const decodedOptions = JSON.parse(options);
                    const decodedResponse = JSON.parse(data.ga4Data.ga4_data);

                    console.log(decodedOptions);
                    console.log(decodedResponse);

                    console.log(window);
                }),
            (err) => {
                // console.log(err);
            }
        );
    }

    async getGTMHead(source) {
        fetchQuery(
            ga4Query._mpGTMHead(source)
        ).then(
            (
                async (data) => {

                    // console.log(data);

                    const {[source]: {head}} = data;

                    if (!!head) {
                        // console.log(head);
                        let scripts = document.createRange().createContextualFragment(head).children;
                        Object.entries(scripts).map(([index, script]) => {
                            // script.id = type.toLowerCase() + '_' + action + '_' + index;
                            // console.log(script);
                            document.body.prepend(script);
                        });
                        // const script = document.createElement('script');
                        // script.innerHTML = head;
                        // document.head.insertBefore(script, document.head.childNodes[0]);
                    }

                    // console.log(window);
                }),
            (err) => {
                // console.log(err);
            }
        );
    }


    renderCategoryPage = (args, callback, instance) => {
        const {isCurrentCategoryLoaded, category: {id = 0} = {}} = instance.props;
// console.log(isCurrentCategoryLoaded);
        if (!isCurrentCategoryLoaded) {
            return callback(...args);
        }
        // console.clear();
        // console.log(instance.state);
        // console.log(instance.props);
        // console.log(id);

        if (!id || !window.requestGA4Data) {
            return callback(...args);
        }
        // console.log(window.requestGA4Data);
        this.requestGA4Data('category', id);
        // this.insertGTMCodeScripts();
        // console.log(window);
        return callback(...args);
    };

    componentDidMountCategoryPage = (args, callback, instance) => {
        // console.clear();
        // this.insertGTMCodeScripts();
        // console.log(window);
        return callback(...args);
    };


    componentWillUnmountCategoryPage = (args, callback, instance) => {
        // console.clear();
        // console.log(window);
        return callback(...args);
    };

    componentDidMountRouter = (args, callback, instance) => {
        console.clear();
        console.log('amasty-ga4');
        // return callback(...args);

        browserHistory.listen( location =>  {
            console.clear();
            console.log(location);
            console.log(instance.state);
            console.log(instance.props);
            //
            const {pathname} = location;
            const {currentUrl} = instance.props;
            //
            if (pathname !== currentUrl) {
                // console.clear();
                // console.log(location);
                // console.log(instance.state);
                // console.log(instance.props);
                console.log('route has been changed');
                this.requestGA4Data('product', 1);



            }
            // window.dispatchEvent(new Event("resize"));
            // this.setState({injected:false});
            // let interval = setInterval(() => {
            //     window.dispatchEvent(new Event("resize"));
            //     clearInterval(interval);
            // }, 1);
        });
        return callback(...args);
    };


    componentWillUnmountRouter = (args, callback, instance) => {
        // console.clear();
        return callback(...args);
    };

    renderMainContentCartPage = (args, callback, instance) => {
        // console.clear();
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};

        // console.log(cartTotals);
        window.currentRoute = 'cart'
        // console.log(window.currentRoute);
        window.requestGA4Data = true;
        if (window.location.pathname.includes('/cart')) {
            this.requestGA4Data('checkoutcart', (cartTotals.id) ? cartTotals.id: 0);
        }
        // this.getGTMHead('GTM');
        // this.getGTMHead('GA');
        // this.getGTMHead('FbPixel');
        return callback(...args);
    };


    componentWillUnmountCartPage = (args, callback, instance) => {
        delete window.currentRoute;
        // console.log(window.currentRoute);
        return callback(...args);
    };

    renderStepCheckout = (args, callback, instance) => {
        // console.clear();
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};
        // console.log(cartTotals);
        // console.log(window.currentRoute);
        window.requestGA4Data = true;
        if (window.location.pathname.includes('/checkout')) {
            this.requestGA4Data('checkoutindex', (cartTotals.id) ? cartTotals.id: 0);
            window.currentRoute = 'checkout'

        }

        return callback(...args);
    };

    checkoutSuccessRender = (args, callback, instance) => {
        // console.clear();
        // console.log(instance.state);
        // console.log(instance.props);
        // console.log(window.currentRoute);

        if (window.checkoutsuccess === 'checkoutsuccess') {
            return callback(...args);
        }
        window.checkoutsuccess = 'checkoutsuccess';

        const {orderID} = instance.props;

        this.getGTMdata(JSON.stringify({
            source: 'checkoutsuccess',
            id: orderID
        }));

        // window.currentRoute = 'checkout-success'
        // window.requestGA4Data = true;
        // if (window.location.pathname.includes('/checkout/success')) {
        //     this.requestGA4Data('checkoutsuccess', orderID);
        // }
        return callback(...args);
    };


    componentWillUnmountCheckout = (args, callback, instance) => {
        delete window.currentRoute;
        // console.log(window.currentRoute);
        return callback(...args);
    };

    componentDidUpdateRouter = (args, callback, instance) => {
        // console.clear();
        // console.log(args);
        // console.log(callback);
        // console.log(instance.state);
        // console.log(instance.props);
        // console.log('router changes');
        // console.log(window.currentRoute);
        // console.log(window);
        return callback(...args);
    };

    renderCategoryDetailsSearchPage = (args, callback, instance) => {
        // console.log(args);
        // console.log(instance.state);
        // console.log(instance.props);
        const {search} = instance.props;
        // console.log(search);
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};

        // console.log(cartTotals);
        window.currentRoute = 'searchpage'
        // console.log(window.currentRoute);
        window.requestGA4Data = true;
        if (window.location.pathname.includes('/search')) {
            this.requestGA4Data('catalogsearch', (cartTotals.id) ? cartTotals.id: 0, search);
        }
        return callback(...args);
    };
    renderProductPageContent = (args, callback, instance) => {
        // console.clear();
        // console.log(args);
        // console.log(instance.state);
        // console.log(instance.props);
        const {activeProduct: {id}} = instance.props;

        if (!id || !window.requestGA4Data) {
            return callback(...args);
        }

        window.requestGA4Data = true;
            this.requestGA4Data('product', id);

        return callback(...args);
    };

    addProductToCart = async (args, callback, instance) => {
        // console.info('AddToCart');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        this.getGTMdata(JSON.stringify({
            source: 'add_to_cart',
            product:args[1].products
        }));
        return callback(...args);
    };

    removeProductFromCart = async (args, callback, instance) => {
        // console.info('RemoveFromCart');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};

        this.getGTMdata(JSON.stringify({
            source: 'remove_from_cart',
            item_id: args[1],
            cart_id: (cartTotals.id) ? cartTotals.id: 0
        }));
        // return;
        return callback(...args);
    };

    removeAllItemsFromCart = async (args, callback, instance) => {
        // console.info('removeAllItemsFromCart');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        const cartItems = args[1];
        let newItems = [];
        Object.values(args[1]).forEach((key) => {
            newItems.push(
                {
                    cart_item_uid: key.cart_item_uid,
                    quantity: key.quantity,
                }
            );
        })

        let newArgs = args;
        newArgs[1] = newItems;

        // console.log(newArgs);


        // return ;

        this.getGTMdata(JSON.stringify({
            source: 'remove_all_from_cart',
            cart_items: cartItems
        }));

        // return ;
        return callback(...newArgs);
    };

    handleChangeQuantity = async (args, callback, instance) => {
        // console.info('handleChangeQuantity');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        const newQty = args[0];
        const {item: {id, quantity}} = instance.props;
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};
        // console.log(newQty);
        // console.log(quantity);

        let qty = 0;
        if (newQty > quantity) {
            qty = newQty - quantity
            // console.log(qty);
            this.getGTMdata(JSON.stringify({
                source: 'increase_qty',
                item_id: id,
                quantity: qty,
                cart_id: (cartTotals.id) ? cartTotals.id: 0
            }));
        }
        if (newQty < quantity) {
            qty = quantity - newQty
            // console.log(qty);
            this.getGTMdata(JSON.stringify({
                source: 'decrease_qty',
                item_id: id,
                quantity: qty,
                cart_id: (cartTotals.id) ? cartTotals.id: 0
            }));
        }

        return callback(...args);

    };

    addItemToWishlist = async (args, callback, instance) => {
        // console.info('addItemToWishlist');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        this.getGTMdata(JSON.stringify({
            source: 'add_to_wishlist',
            product:args[1].items
        }));
        return callback(...args);
    };

    removeItemFromWishlist = async (args, callback, instance) => {
        // console.info('removeItemFromWishlist');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        // this.getGTMdata(JSON.stringify({
        //     source: 'add_to_wishlist',
        //     product:args[1].items
        // }));
        return callback(...args);
    };

}


const {

    componentDidMountCategoryPage,
    componentWillUnmountCategoryPage,
    componentDidMountRouter,
    componentWillUnmountRouter,
    renderMainContentCartPage,
    componentWillUnmountCartPage,
    renderStepCheckout,
    componentWillUnmountCheckout,
    componentDidUpdateRouter,
    // getStaticReducers,
    // mapDispatchToProps,
    // BEFORE_ITEMS_TYPE,
    // getCartQuery,
    // _getCartTotalsFields,
    // checkoutSuccess
    renderCategoryPage,
    renderCategoryDetailsSearchPage,
    renderProductPageContent,
    checkoutSuccessRender,
    addProductToCart,
    removeProductFromCart,
    removeAllItemsFromCart,
    handleChangeQuantity,
    addItemToWishlist,
    removeItemFromWishlist
} = new GtmPlugin();

export default {
    // 'Component/CartItem/Container': {
    //     'member-function': {
    //         handleChangeQuantity
    //     }
    // },
    // 'Store/Cart/Dispatcher': {
    //     'member-function': {
    //         addProductToCart,
    //         removeProductFromCart,
    //         removeAllItemsFromCart
    //     }
    // },
    // 'Route/CategoryPage/Component': {
    //     'member-function': {
    //         componentDidMount: componentDidMountCategoryPage,
    //         componentWillUnmount: componentWillUnmountCategoryPage,
    //         renderContent: renderCategoryPage
    //     }
    // },
    // 'Route/ProductPage/Component': {
    //     'member-function': {
    //         renderProductPageContent: renderProductPageContent
    //     }
    // },
    // 'Route/SearchPage/Component': {
    //     'member-function': {
    //         renderCategoryDetails: renderCategoryDetailsSearchPage,
    //     }
    // },
    // 'Route/CartPage/Component': {
    //     'member-function': {
    //         renderMainContent: renderMainContentCartPage,
    //         componentWillUnmount: componentWillUnmountCartPage
    //     }
    // },
    // 'Route/Checkout/Component': {
    //     'member-function': {
    //         renderStep: renderStepCheckout,
    //         // renderDetailsStep: renderDetailsStepCheckout,
    //         componentWillUnmount: componentWillUnmountCheckout
    //     }
    // },
    'Component/Router/Container': {
        'member-function': {
            componentDidMount: componentDidMountRouter,
            componentWillUnmount: componentWillUnmountRouter,
            componentDidUpdate: componentDidUpdateRouter
        }
    },

    // 'Component/CheckoutSuccess/Component': {
    //     'member-function': {
    //         render:checkoutSuccessRender
    //     }
    // },
    // 'Store/Wishlist/Dispatcher': {
    //     'member-function': {
    //         addItemToWishlist,
    //         removeItemFromWishlist
    //     }
    // },
};
